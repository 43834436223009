import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from "gatsby"
import Settings from 'src/styles/settings'
import Img from "gatsby-image"

import { Media } from 'src/styles/mixins'

const Container = styled.div`
  margin: 0 auto 1rem;
  left: 0;
  right: 0;
  border-radius: 200px;
  overflow: hidden;
  border: solid ${Settings.colors.keyColor};
  box-sizing: border-box;

  ${props => props.card && css`
    position: absolute;
    margin-bottom: 0;
  `}

  ${Media.desktop`
    width: 120px;
    height: 120px;
    top: -60px;
    border-width: .4rem;
  `}

  ${Media.mobile`
    width: 60px;
    height: 60px;
    top: -30px;
    border-width: .3rem;
  `}
`;

const ThumbnailLink = styled(Link)`
`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;
`;

const Avatar = ({ slug, name, image, card }) => (
  <Container card={ card }>
    <ThumbnailLink to={`/@${ slug }`} title={ name }>
      <Image fluid={ image.fluid } alt={ name } />
    </ThumbnailLink>
  </Container>
)

Avatar.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  card: PropTypes.string,
}

export default Avatar
