import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import IcoTwitter from "src/images/ico_twitter.svg"
import IcoFacebook from "src/images/ico_facebook.svg"
import IcoInstagram from "src/images/ico_instagram.svg"
import IcoLine from "src/images/ico_line.svg"

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

const Container = styled.div`
list-style: none;
display: flex;
justify-content: center;
margin: 0 0 1rem;
padding: 0;
`;

const Item = styled.li`
margin: 0 .5rem;
`;

const ItemLink = styled.a`
display: block;
width: 2rem;
`;

const TwitterImage = styled(IcoTwitter)`
  width: 100%;
  height: auto;
`;

const FacebookImage = styled(IcoFacebook)`
  width: 100%;
  height: auto;
`;

const InstagramImage = styled(IcoInstagram)`
  width: 100%;
  height: auto;
`;

const LineImage = styled(IcoLine)`
  width: 100%;
  height: auto;
`;

const Account = ({ twitter, facebook, instagram, line }) => (
  <Container>
    { twitter &&
      <Item>
        <ItemLink href={`https://twitter.com/${ twitter }`} title="" target="_blank" rel="noreferrer noopener">
          <TwitterImage />
        </ItemLink>
      </Item>
    }
    { facebook &&
      <Item>
        <ItemLink href={`https://www.facebook.com/${ facebook }`} title="" target="_blank" rel="noreferrer noopener">
          <FacebookImage />
        </ItemLink>
      </Item>
    }
    { instagram &&
      <Item>
        <ItemLink href={`https://www.instagram.com/${ instagram }`} title="" target="_blank" rel="noreferrer noopener">
          <InstagramImage />
        </ItemLink>
      </Item>
    }
    { line &&
      <Item>
        <ItemLink href={`https://line.me/R/ti/p/${ line }`} title="" target="_blank" rel="noreferrer noopener">
          <LineImage />
        </ItemLink>
      </Item>
    }
  </Container>
)

Account.propTypes = {
  twitter: PropTypes.string,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  line: PropTypes.string,
}

export default Account
