import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import styled, { css } from 'styled-components'

import Avatar from "src/components/atoms/Avatar"
import Account from "src/components/molecules/Account"

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

const Container = styled.div`
  text-align: center;

  ${Media.desktop`
    margin-bottom: 4rem;
  `}

  ${Media.mobile`
    margin-bottom: 1rem;
  `}
`;

const Inner = styled.div`
  max-width: 1068px;
  margin: 0 auto;
  color: ${Settings.colors.keyColor};

  ${Media.desktop`
    padding: 4rem 0;
  `}

  ${Media.mobile`
    padding: 2rem 0;
  `}
`;

const Title = styled.h1`
  margin: 0;
  font-size: 2rem;

  ${Media.desktop`
    font-size: 2rem;
    margin-bottom: 1rem;
  `}

  ${Media.mobile`
    font-size: 1.14rem;
    margin-bottom: .5rem;
  `}
`;

const Description = styled.div`

  ${Media.mobile`
    margin: 0 1rem;
  `}
`;

const Head = ({ title, description, image, slug, twitter, instagram, line, facebook }) => (
  <Container>
    <Inner>
      { image && slug && title &&
        <Avatar slug={ slug } name={ title } image={ image } />
      }

      <Title dangerouslySetInnerHTML={{ __html: title }} />

      { twitter && instagram && line && facebook &&
        <Account twitter={twitter} instagram={instagram} line={line} facebook={facebook} />
      }

      { description ?
        <Description dangerouslySetInnerHTML={{ __html: description }} />:
        <Description>{title}の記事。</Description>
      }
    </Inner>
  </Container>
)

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  slug: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  line: PropTypes.string,
  facebook: PropTypes.string,
}

export default Head
