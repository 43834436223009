import React from 'react'
import { graphql } from 'gatsby'

import styled, { css } from 'styled-components'
import { Media } from 'src/styles/mixins'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import Head from 'src/components/molecules/Head'
import Wrapper from 'src/components/organisms/Wrapper'
import Archive from 'src/components/molecules/Archive'

const Tag = ({ data }) => {
  const {
    id,
    title,
    description,
    posts,
  } = data.contentfulTags
  const settings = data.contentfulSettings
  return (
    <Layout>
      <SEO />

      <Head
        title={ title }
        description={ description } />

      <Wrapper settings={ settings }>
        <Archive type="main" posts={ posts } />
      </Wrapper>
    </Layout>
  )
}

export default Tag

export const query = graphql`
  query($slug: String!) {
    contentfulTags(slug: { eq: $slug }) {
      id
      title
      description
      posts {
        id
        title
        excerpt {
          childMarkdownRemark {
            excerpt
            html
          }
        }
        slug
        tags {
          id
          slug
          title
          group
        }
        image {
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }

    contentfulSettings(node_locale: {eq: "ja-JP"}) {
      siteName
      siteCaption
      siteDescription
      twitter
      facebook
      instagram
      line
    }
  }
`